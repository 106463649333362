import React from 'react';
import { oneOfType, string } from 'prop-types';
import classNames from 'classnames';

import { propTypes } from '../../util/types';

import css from './ResponsiveBackgroundWatermark.module.css';

const ResponsiveBackgroundWatermark = props => {
  const {
    className,
    rootClassName,
    image,
    as,
    alt,
    ...otherProps
  } = props;

  const Tag = as || 'div';

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Tag className={classes} {...otherProps}>
      <img className={css.backgroundImage} src="https://sharetribe-assets.imgix.net/64ca83c7-5de6-4417-8851-6d5a930c7e1e/raw/cb/7192e577306186af5a66a99c22a0ec1e6676cf?auto=format&fit=clip&h=3600&w=3600&s=8c426cc3e8147742a11bdf1db58f0a7f"/>
    </Tag>
  );
};

ResponsiveBackgroundWatermark.defaultProps = {
  className: null,
  rootClassName: null,
  as: 'div',
  image: null,
  alt: null,
};

ResponsiveBackgroundWatermark.propTypes = {
  className: string,
  rootClassName: string,
  as: string,
  image: oneOfType([string, propTypes.imageAsset]),
  alt: string,
};

export default ResponsiveBackgroundWatermark;
