import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import FilterPopup from '../../containers/SearchPage/FilterPopup/FilterPopup';
import PriceFilterPopup from '../../containers/SearchPage/PriceFilter/PriceFilterPopup';
import { useConfiguration } from '../../context/configurationContext';
import { Button, FieldDateRangeController, LocationAutocompleteInput } from '../index';
import css from './MultiSearch.module.css';

/**
 * Multi Search Component used for example in home page Hero section
 *
 * @returns {JSX.Element}
 * @constructor
 */
const MultiSearch = () => {
  const history = useHistory();
  const config = useConfiguration();

  const categories = config.listing.listingFields.find(field => field.key === 'category')?.enumOptions || [];

  // Refs
  let popupControllerRef = useRef(null);
  const categoriesPopupRef = useRef(null);

  // State
  const [isCategoriesPopupOpen, setIsCategoriesPopupOpen] = useState(false);
  const [priceRange, setPriceRange] = useState(null);
  const [priceRangeParam, setPriceRangeParam] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [dateRangeParam, setDateRangeParam] = useState(null);
  const [keywords, setKeywords] = useState(null);
  const [address, setAddress] = useState('Poland');
  const [bounds, setBounds] = useState('54.8377,24.1451,49.0023,14.1229');
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Set date range
  const handleDateRangeChange = data => {
    const { dates } = data;
    const { startDate, endDate } = dates;
    setDateRange([moment(startDate).format('DD.MM.YYYY'), moment(endDate).format('DD.MM.YYYY')]);
    setDateRangeParam(`${moment(startDate).format('YYYY-MM-DD')},${moment(endDate).format('YYYY-MM-DD')}`);
  }

  // Set prices
  const handlePriceRangeChange = data => {
    const { price } = data;
    setPriceRange(price.replace(',', ' - '));
    setPriceRangeParam(price);
  }

  // Perform search action - redirect to search page with params
  const handleSearchPerform = () => {

    const params = new URLSearchParams({
      address,
      bounds,
      ...(keywords ? { keywords } : {}),
      ...(dateRangeParam ? { dates: dateRangeParam } : {}),
      ...(priceRangeParam ? { price: priceRangeParam } : {}),
      ...(selectedCategories.length ? { pub_category: selectedCategories } : {}),
    });

    history.push(`/s?${params.toString()}`);
  }

  // Set categories as id's array
  const handleCategoriesChange = event => {
    const { checked, id } = event.target;

    checked
      ? setSelectedCategories(categories => [...new Set([...categories, id.toString()])])
      : setSelectedCategories(categories => categories.filter(item => item !== id.toString()));
  }

  // Simply toggle categories popup
  const toggleCategoriesPopup = () => {
    setIsCategoriesPopupOpen(!isCategoriesPopupOpen);
  }

  // Categories popup click-outside functionality
  useEffect(() => {
    function handleCategoriesClickOutside(event) {
      if (categoriesPopupRef.current && !categoriesPopupRef.current.contains(event.target)) {
        setIsCategoriesPopupOpen(false);
      }
    }
    window.addEventListener("mousedown", handleCategoriesClickOutside);
    return () => { window.removeEventListener("mousedown", handleCategoriesClickOutside) };
  }, [categoriesPopupRef]);

  const [location, setLocation] = useState(null)

  const setLocationByBounds = location => {
    const { ne, sw } = location.bounds;
    setAddress(location.address)
    setBounds(`${ne.lat},${ne.lng},${sw.lat},${sw.lng}`);
  }

  const setLocationByBBox = location => {
    setAddress(location.place_name)
    setBounds([...location.bbox].reverse().join(','));
  }

  const updateLocation = (location) => {
    setLocation(location)

    let selected;
    if (!selected && location.selectedPlace) {
      selected = location.selectedPlace;
      setLocationByBounds(selected);
    }

    if (!selected && location.predictions?.length > 0) {
      selected = location.predictions[0].id !== "current-location" ? location.predictions[0] : location.predictions[1];
    }

    if (!selected) return;

    if (selected?.bounds) setLocationByBounds(selected);
    if (selected?.bbox) setLocationByBBox(selected);
  }

  // JSX
  return (
    <div className={css.multiSearchWrapper}>
      <input type="text" className={css.input} placeholder="Wpisz nazwę lub markę roweru" onChange={(event) => setKeywords(event.target.value)} />

      <LocationAutocompleteInput
        iconClassName={css.locationIcon}
        inputClassName={css.input}
        placeholder="Lokalizacja"
        id="location"
        label="Location"
        input={{
          name: 'location',
          onBlur: updateLocation,
          onFocus: () => { },
          onChange: updateLocation,
          value: location
        }}
        meta={{}}
      />


      {/* TODO: Temporary hide category filter until we have decision about this */}
      {/* <div className={css.categoriesSelectorWrapper}>
        <PopupOpenerButton toggleOpen={() => toggleCategoriesPopup()}>
          <strong>Kategorii</strong>
          {selectedCategories.length ? (<span>Wybrano {selectedCategories.length}</span>) : (<></>)}
        </PopupOpenerButton>
        {isCategoriesPopupOpen && (
          <div className={css.categoriesSelector} ref={categoriesPopupRef}>
            {categories.map(({ label, option }) => (
              <div className={css.category} key={option}>
                <div><input type="checkbox" id={option} name="category" value={option} onChange={(event) => handleCategoriesChange(event)} /></div>
                <label htmlFor={option}>{label}</label>
              </div>
            ))}
          </div>
        )}
      </div> */}

      {/* Date selector */}
      <FilterPopup
        label={(
          <div className={css.buttonDefaultLabel}>
            <strong>Daty dostępności</strong>
            {dateRange && (<span>{dateRange[0]} - {dateRange[1]}</span>)}
          </div>
        )}
        onSubmit={(data) => handleDateRangeChange(data)}
        className={css.filterButton}
      >
        <FieldDateRangeController
          name="dates"
          controllerRef={node => {
            popupControllerRef = node;
          }}
        />
      </FilterPopup>

      {/* Price range selector */}
      <PriceFilterPopup
        label={(
          <div className={css.buttonDefaultLabel}>
            <strong>Ceny wypożyczenia</strong>
            {priceRange && (<span>{priceRange} PLN</span>)}
          </div>
        )}
        min={0}
        max={500}
        step={10}
        onSubmit={(data) => handlePriceRangeChange(data)}
        className={css.filterButton}
      />

      <Button className={css.actionButton} onClick={() => handleSearchPerform()}>Szukaj</Button>
    </div>
  );
}

export default MultiSearch
